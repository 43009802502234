import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

//import axios from 'axios';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import './_Book.scss';

import progressIcon from '../assets/progress_icon.png';
import playIcon from '../assets/play_icon.png';
import pauseIcon from '../assets/pause_icon.png';
import skipIcon from '../assets/skip_icon.png';

import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';

momentDurationFormatSetup(moment);

let audio;
let progressWidth;
let audioInterval;
let loadingInterval;

class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: '-:-',
      currentTime: '-:-',
      progress: 0,
      loaded: false,
      loadedPercents: 0,
      redirect: false,
      paused: true,
      isIOS: undefined,
      error: false
    };
  }

  componentWillMount () {
    this.setState({
      isIOS: !!(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
    }, () => {
      console.log('is ios: ' + this.state.isIOS);
    });
  }

  componentDidMount () {

    const state = {};
    const title = 'Book';
    const url = window.location.hash;
    window.history.pushState(state, title, url);
    window.onpopstate = (e) => {
      this.redirect('home');
      window.onpopstate = null;
    };

    window.addEventListener('offline', () => {
      this.setState({
        error: true
      })
    });

    window.onbeforeunload = () => {
      this.resetAudio();
    }

    progressWidth = document.getElementsByClassName('book__progress')[0].offsetWidth - 30;

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'page_view',
      eventProperties: {
        page_virtual_path: `/family/luister-verhalen/book${this.props.index}`,
        page_type: 'family'
      }
    });

    try {
      audio = new Audio();
      audio.preload = 'auto';

      this.dragElement(document.getElementsByClassName('book__progress-icon')[0]);
      if (!this.state.isIOS) {
        audio.oncanplaythrough = () => {
          if (!this.state.loaded) {
            this.setState({
              loaded: true
            }, () => {
              console.log('canplaythrough');
              this.play();
            })
          }
        }
      }

      audio.src = this.props.url;
      audio.load();
      loadingInterval = setInterval(() => {
        if (audio.buffered.length) {
          let prevLoaded = this.state.loaded;
          this.setState({
            loadedPercents: (audio.buffered.end(0) * 100) / audio.duration,
            loaded: this.state.isIOS ? ((audio.buffered.end(0) * 100) / audio.duration >= 20) : prevLoaded
          }, () => {
            console.log(`loaded ${this.state.loadedPercents}%`)
            console.log(audio.buffered.end(0));
            if (this.state.isIOS && prevLoaded !== this.state.loaded) {
              this.play();
            }
            if (+this.state.loadedPercents >= 100) {
              clearInterval(loadingInterval);
            }
          })
        }
      }, 1000)
      /*audio.ontimeupdate = (e) => {
        this.setState({
          currentTime: audio.currentTime < 1 ? '0:00' : moment.duration(audio.currentTime, 'seconds').format(),
          progress: (audio.currentTime * 100 / audio.duration).toFixed(2)
        })
      }*/
      audio.onloadedmetadata = () => {
        console.log(`duration` + moment.duration(audio.duration, 'seconds').format());
        this.setState({
          duration: moment.duration(audio.duration, 'seconds').format(),
          currentTime: audio.currentTime < 1 ? '0:00' : moment.duration(audio.currentTime, 'seconds').format()
        })
      }
      audio.onerror = (err) => {
        console.log(JSON.stringify(err));
        this.setState({
          error: true
        })
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      this.setState({
        error: true
      })
    }

  }

  dragElement = (elmnt) => {
    let wasPaused;
    let drag_start_point = 0;
    let moved_px = 0;
    let movedPercents = 0;
    let newPos = 0;
    let initialProgress;

    elmnt.ontouchstart = (e) => {
      console.log('ontouchstart');
      wasPaused = this.state.paused;
      initialProgress = this.state.progress;
      drag_start_point = e.touches[0].clientX;
      if (!wasPaused) {
        this.pause();
      }
    }

    elmnt.ontouchmove = (e) => {
      console.log('touchmove')
      if (this.state.paused) {
        moved_px = e.touches[0].clientX - drag_start_point;
        let delta = (moved_px * 100) / progressWidth;
        movedPercents = +initialProgress + delta;
        console.log(initialProgress, movedPercents, moved_px, delta);
        if ( movedPercents >= 0 && movedPercents <= 100 ) {
          newPos = movedPercents;
          audio.currentTime = audio.duration * (this.state.progress / 100);
          this.setState({
            progress: newPos,
            //currentTime: audio.currentTime < 1 ? '0:00' : moment.duration(audio.currentTime, 'seconds').format()
          })
        }
      }
    }

    elmnt.ontouchend = (e) => {
      console.log('ontouchend');
      audio.currentTime = audio.duration * (this.state.progress / 100);
      this.setState({
        loadedPercents: (audio.buffered.end(0) * 100) / audio.duration
      })
      /*this.setState({
        currentTime: audio.currentTime < 1 ? '0:00' : moment.duration(audio.currentTime, 'seconds').format()
      }, () => {
        if (!wasPaused) {
          this.play();
        }
      })*/
      if (!wasPaused) {
        this.play();
      }
    }
  }

  togglePlay = () => {
    if (this.state.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  play = () => {
    try {
      this.setState({
        paused: false
      }, () => {
        audio.play();
        audioInterval = setInterval(() => {
          this.setState({
            currentTime: audio.currentTime < 1 ? '0:00' : moment.duration(audio.currentTime, 'seconds').format(),
            progress: (audio.currentTime * 100 / audio.duration).toFixed(2)
          })
        }, 1000);
      })
    } catch (err) {
      console.log(JSON.stringify(err));
      this.setState({
        error: true
      })
    }
  }

  pause = () => {
    this.setState({
      paused: true
    }, () => {
      audio.pause();
      clearInterval(audioInterval);
    })
  }

  redirect = (page) => {
    window.dataLayer = window.dataLayer || [];
    const action = page === 'prev' ? 'previous' : 'next';

    window.dataLayer.push({
      event: 'button_click',
      eventAction: action,
      eventLabel: `/family/luister-verhalen${this.props[page]}`
    });

    this.resetAudio();
    this.setState({
      redirect: page
    })
  }

  resetAudio = () => {
    window.onpopstate = null;
    if (audio) {
      audio.pause();
      audio = undefined;
    }
    if (audioInterval) {
      clearInterval(audioInterval);
    }
    if (loadingInterval) {
      clearInterval(loadingInterval);
    }
  }

  render() {

    if (this.state.redirect) {
      if (this.state.redirect === 'prev') {
        return (<Redirect to={this.props.prev } />);
      } else if(this.state.redirect === 'next') {
        return (<Redirect to={this.props.next } />);
      }
      else {
        return (<Redirect to='/' />);
      }
    } else {
      return(
        <section className={`book book-${this.props.index}`}>
          {this.state.loaded || <Loader />}
          <ErrorMessage visible={this.state.error}
                        refreshCb={() => {this.redirect('home')}} />
          <div className='book__heading'>
            <strong>{this.props.title}</strong>
            <p>{this.props.description}</p>
            <em>{this.props.writer}</em>
          </div>
          <div className='book__cover'>
            <div className='book__cover-bg'>
            </div>
            <div className='book__cover-chars'></div>
          </div>
          {/*<img className='book__cover' src={this.props.cover} alt='' />*/}
          <div className='book__controls-wrapper'>
            <div className='book__progress'
                 role="progressbar"
                 aria-valuenow={this.state.currentTime}
                 aria-valuemin="0"
                 aria-valuemax={this.state.duration}>
              <div className='book__progress-complete'
                   style={{ width: `${this.state.loadedPercents}%` }}>
              </div>
              <img className='book__progress-icon'
                   src={progressIcon}
                   alt=''
                   style={{ marginLeft: `${this.state.progress}%` }}/>
            </div>
            <span className='book__progress-value'>
              {this.state.currentTime}
            </span>
            <span className='book__progress-value'>
              {this.state.duration}
            </span>
            <img className={`book__control prev ${this.props.prev ? '' : 'disabled'}`}
                 onClick={() => { if (this.props.prev) this.redirect('prev'); }}
                 src={skipIcon}
                 alt='previous' />
            <img className='book__control play'
                 onClick={this.togglePlay}
                 src={this.state.paused ? playIcon : pauseIcon}
                 alt='play' />
            <img className={`book__control next ${this.props.next ? '' : 'disabled'}`}
                 onClick={() => { if (this.props.next) this.redirect('next'); }}
                 src={skipIcon}
                 alt='next' />
          </div>
        </section>
      )
    }

  }
}

export default Book;


/*
let myaudio = new Audio('mysong.mp3');
myaudio.play(); - This will play the music.
myaudio.pause(); - This will stop the music.
myaudio.duration; - Returns the length of the music track.
myaudio.currentTime = 0; - This will rewind the audio to the beginning.
myaudio.loop = true; - This will make the audio track loop.
myaudio.muted = true; - This will mute the track
*/
