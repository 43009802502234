import book7Cover from '../assets/book7_wetenschapper.png';
import book8Cover from '../assets/book8_sportkampioen.png';
import book9Cover from '../assets/book9_dappere-avonturier.png';
import book10Cover from '../assets/book10_innovatieve-ontwerpen.png';
import book11Cover from '../assets/book11_inspirerende-leider.png';
import book12Cover from '../assets/book12_fantasierijke-maker.png';
import book13Cover from '../assets/book13_happystories.png';

import book1Cover from '../assets/book1cover_preview.png';
import book2Cover from '../assets/book2cover_preview.png';
import book3Cover from '../assets/book3cover_preview.png';
import book4Cover from '../assets/book4cover_preview.png';
import book5Cover from '../assets/book5cover_preview.png';
import book6Cover from '../assets/book6cover_preview.png';

const is14week = true;
const is16week = true;
const is18week = true;

const books = Object.freeze({
  'happy_story': {
    title: 'Happy Story Noes & Imo',
    description: 'Over twee aliens die naar planeet aarde gaan om te ontdekken wat een gezin is',
    author: null,
    bookCover: book13Cover,
    redirectTo: 13,
    path: '/book13',
    pathPrev: '/book2',
    pathNext: '/book11',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2FMcDonald_s%20-%20Noes%20En%20Imo%20Final.mp3?alt=media&token=9e33c4c8-7e5f-43da-ac95-4900676e08ac',
    className: 'standard-cover',
    isVisible: true,
  },
  'inspirerende_leider': {
    title: 'Ik kan een inspirerende leider worden',
    description: 'Luister naar de verhalen van deze moedige strijders en hoe ze de wereld veranderden.',
    author: null,
    bookCover: book11Cover,
    redirectTo: 11,
    path: '/book11',
    pathPrev: '/book13',
    pathNext: '/book12',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2F226152%20-%20McD%20-%20luisterboekjes%20-%20Leider%20-%202023%2003%2028.mp3?alt=media&token=eb5f3f48-7e6f-47bf-911d-cbbf2c944d5d',
    className: 'standard-cover',
    isVisible: is18week,
  },
  'fantasierijke_maker': {
    title: 'Ik kan een fantasierijke maker worden',
    description: 'Van uitvindingen en kunst, tot sprookjes. Met een beetje fantasie, is alles mogelijk!',
    author: null,
    bookCover: book12Cover,
    redirectTo: 12,
    path: '/book12',
    pathPrev: '/book11',
    pathNext: '/book9',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2F226152%20-%20McD%20-%20luisterboekjes%20-%20Maker%20-%202023%2003%2028.mp3?alt=media&token=25f1676f-5179-4ec4-86ea-1abaef994737',
    className: 'standard-cover',
    isVisible: is18week,
  },
  'dappere_avonturier': {
    title: 'Ik kan een dappere avonturier worden',
    description: 'Laat je inspireren door drie van de grootste avonturiers die hun dromen waarmaakten!',
    author: null,
    bookCover: book9Cover,
    redirectTo: 9,
    path: '/book9',
    pathPrev: is18week ? '/book12' : '/book2',
    pathNext: '/book10',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2F226152%20-%20McD%20-%20luisterboekjes%20-%20Avonturier%20-%202023%2003%2028.mp3?alt=media&token=ba944ec1-2937-4582-83e3-86b5aec70609',
    className: 'standard-cover',
    isVisible: is16week,
  },
  'innovatieve_ontwerpen': {
    title: 'Ik kan een innovatieve ontwerper worden',
    description: 'Deze creatieve mensen veroverden de wereld met hun ontwerpen. Luister maar!',
    author: null,
    bookCover: book10Cover,
    redirectTo: 10,
    path: '/book10',
    pathPrev: '/book9',
    pathNext: '/book7',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2F226152%20-%20McD%20-%20luisterboekjes%20-%20Ontwerper%20-%202023%2003%2028.mp3?alt=media&token=657e3d03-ede8-4bc6-9c32-3737365b78e6',
    className: 'standard-cover',
    isVisible: is16week,
  },
  'wetenschapper': {
    title: 'Ik kan een nieuwsgierige wetenschapper worden',
    description: 'Al nieuwsgierig geworden? Ontdek wat deze grote wetenschappers bedachten!',
    author: null,
    bookCover: book7Cover,
    redirectTo: 7,
    path: '/book7',
    pathPrev: is16week ? '/book10' : '/book2',
    pathNext: '/book8',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2F226152%20-%20McD%20-%20luisterboekjes%20-%20Wetenschapper%20-%202023%2003%2028.mp3?alt=media&token=32e7bf09-6cf8-44fa-988c-ae0c622c0113',
    className: 'standard-cover',
    isVisible: is14week,
  },
  'sportkampioen': {
    title: 'Ik kan een sportkampioen worden',
    description: 'Gek op sport en wil je ook naar de top? Laat deze sportsterren je voorbeeld zijn!',
    author: null,
    bookCover: book8Cover,
    redirectTo: 8,
    path: '/book8',
    pathPrev: '/book7',
    pathNext: '/book5',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2F226152%20-%20McD%20-%20luisterboekjes%20-%20Sport%20-%202023%2003%2028.mp3?alt=media&token=ec3a2db9-16f6-43a9-b9c4-49932ff4da22',
    className: 'standard-cover',
    isVisible: is14week,
  },
  'book5': {
    title: 'Avonturen van de Boomtop-tweeling',
    description: 'De tweelingen volgen het spoor van een tijger',
    author: 'Cressida Cowell',
    bookCover: book5Cover,
    redirectTo: 5,
    path: '/book5',
    pathPrev: is14week ? '/book8' : '/book2',
    pathNext: '/book6',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2FMCD_Book5.mp3?alt=media&token=46401809-e35d-4f5e-a1ac-337336c3bd35',
    className: '',
    isVisible: true,
  },
  'book6': {
    title: 'Avonturen van de Boomtop-tweeling',
    description: 'De tweelingen spotten een walvis',
    author: 'Cressida Cowell',
    bookCover: book6Cover,
    redirectTo: 6,
    path: '/book6',
    pathPrev: '/book5',
    pathNext: '/book3',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2FMCD_Book6.mp3?alt=media&token=a4073351-f7d6-464d-ab74-e5d3428b99e5',
    className: '',
    isVisible: true,
  },
  'book3': {
    title: 'Avonturen van de Boomtop-tweelingen',
    description: 'De tweelingen ontmoeten een hatzegopteryx',
    author: 'Cressida Cowell',
    bookCover: book3Cover,
    redirectTo: 3,
    path: '/book3',
    pathPrev: '/book6',
    pathNext: '/book4',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2FMCD_Book3.mp3?alt=media&token=bac2b2bc-482a-47c2-b634-bc59d67b6db1',
    className: '',
    isVisible: true,
  },
  'book4': {
    title: 'Avonturen van de Boomtop-tweelingen',
    description: 'De tweelingen ontmoeten twee tyrannosaurussen',
    author: 'Cressida Cowell',
    bookCover: book4Cover,
    redirectTo: 4,
    path: '/book4',
    pathPrev: '/book3',
    pathNext: '/book1',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2FMCD_Book4.mp3?alt=media&token=4266036a-48c3-491e-9070-1c1c30d7eb87',
    className: '',
    isVisible: true,
  },
  'book1': {
    title: 'Avonturen van de Boomtop-tweelingen',
    description: 'De tweelingen ontdekken een massospondylus',
    author: 'Cressida Cowell',
    bookCover: book1Cover,
    redirectTo: 1,
    path: '/book1',
    pathPrev: '/book4',
    pathNext: '/book2',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2FMCD_Book1.mp3?alt=media&token=d8f9395f-b51f-40c3-983f-2510817f531f',
    className: '',
    isVisible: true,
  },
  'book2': {
    title: 'Avonturen van de Boomtop-tweelingen',
    description: 'De tweelingen klimmen op een camarasaurus',
    author: 'Cressida Cowell',
    bookCover: book2Cover,
    redirectTo: 2,
    path: '/book2',
    pathPrev: '/book1',
    pathNext:
      is18week ?'/book13' :
        is16week ? '/book9' :
          is14week ? '/book7' :
            '/book5',
    audioUrl: 'https://firebasestorage.googleapis.com/v0/b/mcdonald-s-fc045.appspot.com/o/Happy%20meal%20audio%20books%2FMCD_Book1.mp3?alt=media&token=d8f9395f-b51f-40c3-983f-2510817f531f',
    className: '',
    isVisible: true,
  },
});

export default books;