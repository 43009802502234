import React from 'react';
import {HashRouter as Router, Route} from 'react-router-dom';
import Home from './pages/Home';
import Book from './pages/Book';
import books from './pages/booksData';
import './_App.scss';

const App = () => {
  return (
    <div className='App'>
      <Router>
        <div className='router-wrapper'>
          <Route path='/' exact component={() => <Home/>}/>

          {books && Object.keys(books).length > 1 &&
            Object.keys(books)
              .filter((bookName) => books[bookName].isVisible)
              .map(function (bookName, index) {
                  let book = books[bookName];
                  return (
                    <Route key={index}
                           path={book.path}
                           component={() => <Book title={book.title}
                                                  description={book.description}
                                                  writer={book.author}
                                                  index={book.redirectTo}
                                                  url={book.audioUrl}
                                                  next={book.pathNext}
                                                  prev={book.pathPrev}
                           />}/>
                  )
                }
              )}
        </div>
      </Router>
    </div>
  )
}

export default App;
