// event listener for bridge ready
/* global mcd */

export const tagsToPlexure = (tags) => {
  if (process.env.NODE_ENV === 'development') return;

  return new Promise((resolve, reject) => {
    let user = mcd.bridge.message('user');

    user.send({ addTags: tags });
    user.on('data', function (data) {
      resolve(data);
    });
    user.on('error', function (error) {
      reject(error);
    });
    user.on('done', function () {
      // no more event will be emitted
      console.info('add/remove tags done');
    });
  });
};

export const getTags = () => {
  if (process.env.NODE_ENV === 'development') return;

  return new Promise((resolve, reject) => {
    let user = mcd.bridge.message('user');

    user.send({'getTags' : true});
    user.on('data', function(data){
      resolve(data);
    });
    user.on('error', function(error){
      reject(error);
    });
    user.on('done', function(){
      //no more event will be emitted
      console.info('get tags done');
    });
  });
};