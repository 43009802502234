import React, { Component } from 'react';

import './_ErrorMessage.scss';

class ErrorMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  refresh = () => {
    if (this.props.visible) {
      this.props.refreshCb();
    }
  }

  render() {
    return (
      <div className={`error ${this.props.visible ? 'visible' : ''}`}>
        <p>Seems that something went wrong. Do you want to refresh the page?</p>
        <button onClick={this.refresh}>Refresh</button>
      </div>
    );
  }
}

export default ErrorMessage;