import React, { Component } from 'react';

import './_Loader.scss';

import Spinner from '../assets/spinner.svg';

class Loader extends Component {
  render() {
    return (
      <div className='loader'>
        <img src={Spinner} alt='Loading...' />
      </div>
    );
  }
}

export default Loader;